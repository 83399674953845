/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExistingLocation = {
    type: ExistingLocation.type;
    banId?: string | null;
};
export namespace ExistingLocation {
    export enum type {
        NUMERO = 'NUMERO',
        VOIE = 'VOIE',
        TOPONYME = 'TOPONYME',
    }
}

